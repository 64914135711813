<script lang="ts" setup>
import { reactive, ref } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'



const ruleFormRef = ref<FormInstance>()

definePageMeta({
    middleware: ["guest-only"],
    //layout: false,
})

const { login } = useAuth();



const form = reactive({
    data: {
        login: '',
        pass: '',
        rememberMe: true,
    },
    error: "",
    pending: false,

})

const isAdmin = useAdmin();

async function onLoginClick() {
    console.log('onLoginClick')
    try {

        
        form.error = "";
        form.pending = true;
        
        await login(form.data.login, form.data.pass, form.data.rememberMe);

        const redirect = isAdmin.value ? "/private" : "/private"; // "/admin"
        await navigateTo(redirect);


    } catch (error: any) {
        console.error(error);
    } finally {
        form.pending = false;
    }
}

const rules = reactive<FormRules<typeof form>>({
    //pass: [{ validator: validatePass, trigger: 'blur' }],
    //checkPass: [{ validator: validatePass2, trigger: 'blur' }],
    //age: [{ validator: checkAge, trigger: 'blur' }],
})


</script>

<template>

    <el-form ref="ruleFormRef" style="max-width: 600px" :model="form" status-icon :rules="rules" label-width="auto"
        class="login-form" label-position="left">
        <el-form-item label="Логин" prop="login">
            <el-input v-model="form.data.login" autocomplete="off" />
        </el-form-item>
        <el-form-item label="Пароль" prop="pass">
            <el-input v-model="form.data.pass" type="password" autocomplete="off" />
        </el-form-item>
        <el-form-item label="Запомнить">
            <el-switch v-model="form.data.rememberMe" />
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="onLoginClick">
                Войти
            </el-button>
        </el-form-item>
    </el-form>
</template>

<style scoped>
.login-form {
    margin: 0 auto;
}
</style>